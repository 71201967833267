<template>
  <div>
    <button @click="$refs['modal-new'].isShown = true; activeTemplate = {is_active: true}"
            class="rounded text-gray-100 px-3 py-1
    float-right hover:shadow-inner align-middle inline-flex items-center
    bg-blue-500 hover:bg-blue-700">
      Neue Vorlage</button>
    <table class="mt-5 min-w-full leading-normal">
      <tr>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-5">
          #
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Name
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Farbe
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Fälligkeit in Tagen
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-30">
          Status
        </th>
        <th class="px-5 py-2 border-b-2 border-gray-200 text-left text-xs font-semibold
              text-gray-600 tracking-wider w-23">
          Aktion
        </th>
      </tr>
      <tr v-for="(template, index) in templates" :key="template.id">
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-5">
          {{index + 1}}
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          {{template.name}}
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          <span class="inline-flex">{{template.color}}</span> <span
          class="inline-flex h-3 w-3 rounded-lg"
                                   :style="{backgroundColor: template.color}"></span>
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          {{template.due_days}}
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          <span v-if="template.is_active">Aktiv</span>
          <span v-if="!template.is_active">Inaktiv</span>
        </td>
        <td class="px-5 py-2 border-b border-gray-200 bg-white text-sm w-30">
          <span @click="activeTemplate = template; $refs['modal-edit'].isShown = true;"
            class="inline cursor-pointer text-blue-500 hover:text-blue-700 mr-2">
            <svg class="inline w-4 h-4 -mt-1 mr-1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"><path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" /><path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" /></svg>
            Bearbeiten</span>
          <span @click="activeTemplate = template; $refs['modal-delete'].isShown = true;"
            class="inline cursor-pointer text-red-500 hover:text-red-700 border-l mr-2 pl-2">
            <svg class="inline w-4 h-4 -mt-1 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" /></svg>
            Entfernen</span>
          <span @click="activeTemplate = template; $refs['modal-list'].isShown = true;"
                class="inline cursor-pointer text-blue-500 hover:text-blue-700 mr-3 border-l pl-2">
            <svg class="inline w-4 h-4 -mt-1 mr-1" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" /></svg>
            Liste bearbeiten
          </span>
        </td>
      </tr>
    </table>
    <ModalSm ref="modal-new" customClass="h-30 w-80">
      <template v-slot:header>
        Vorlage hinzufügen
      </template>
      <template v-slot:body>
        <InputCom name="name" label="Name" v-model="slotTemplate.name"
                  :val="slotTemplate.name"></InputCom>
        <div class="h-16">
          <v-swatches v-model="slotTemplate.color" class="absolute z-50 w-full" shapes="circles">
            <InputCom slot="trigger" name="color" label="Farbe" v-model="slotTemplate.color"
                      :val="slotTemplate.color"></InputCom>
            <div slot="trigger" class="h-6 w-6 rounded right-20 top-7 absolute"
                 :style="{backgroundColor: slotTemplate.color}"></div>
          </v-swatches>
        </div>
        <InputCom name="due_days" label="Fälligkeit in Tagen" v-model="slotTemplate.due_days"
                  input-type="number"
                  :val="slotTemplate.due_days"></InputCom>
        <ToggleSwitch class="mt-2"
                      labelTrue="Aktiv" labelFalse="Inaktiv" :val="true"
                      v-model="slotTemplate.is_active" name="is_active"
                      @change="(value) => slotTemplate.is_active = value"
        ></ToggleSwitch>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="templateStore('button-new-'+slotTemplate.id)"
                        :ref="'button-new-'+slotTemplate.id"
                        :pause="750"
                        @reset="$refs['modal-new'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <ModalSm ref="modal-edit" customClass="h-30 w-80">
      <template v-slot:header>
        Vorlage bearbeiten
      </template>
      <template v-slot:body>
        <InputCom name="name" label="Name" v-model="slotTemplate.name"
                  :val="slotTemplate.name"></InputCom>
        <div class="h-16">
          <v-swatches v-model="slotTemplate.color" class="absolute z-50 w-full" shapes="circles">
          <InputCom slot="trigger" name="color" label="Farbe" v-model="slotTemplate.color"
                    :val="slotTemplate.color"></InputCom>
            <div slot="trigger" class="h-6 w-6 rounded-xl right-20 top-7 absolute"
                 :style="{backgroundColor: slotTemplate.color}"></div>
          </v-swatches>
        </div>
        <InputCom name="due_days" label="Fälligkeit in Tagen" v-model="slotTemplate.due_days"
                  input-type="number"
                  :val="slotTemplate.due_days"></InputCom>
        <ToggleSwitch class="mt-2"
          labelTrue="Aktiv" labelFalse="Inaktiv" :val="slotTemplate.is_active"
                      v-model="slotTemplate.is_active" name="is_active"
                      @change="(value) => slotTemplate.is_active = value"
        ></ToggleSwitch>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="templateUpdate('button-edit-'+slotTemplate.id)"
                        :ref="'button-edit-'+slotTemplate.id"
                        :pause="750"
                        @reset="$refs['modal-edit'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <ModalSm ref="modal-delete" customClass="h-30 w-100">
      <template v-slot:header>
        Vorlage löschen
      </template>
      <template v-slot:body>
        <p>Möchten Sie die Vorlage <span class="text-red-500">{{ slotTemplate.name }}
        </span> wirklich löschen?</p>
      </template>
      <template v-slot:footer>
        <AnimatedButton @click.native="templateDelete('button-delete-'+slotTemplate.id)"
                        :ref="'button-delete-'+slotTemplate.id"
                        on-initial="Ja, Vorlage löschen"
                        on-error="Fehlgeschlagen!"
                        on-success="Erfolgreich gelöscht!"
                        initial-class="bg-red-500 hover:bg-red-700"
                        :pause="750"
                        @reset="$refs['modal-delete'].isShown = false"
                        class="ml-2 float-right">
        </AnimatedButton>
      </template>
    </ModalSm>
    <Modal ref="modal-list" customClass="h-30 w-96 max-h-40">
      <template v-slot:header>
        Liste bearbeiten
      </template>
      <template v-slot:body>
        <List :list-items="slotTemplate.template_lists" :parent-id="slotTemplate.id"
              scope="templatelists"
        ></List>
<!--        <Profile :user-id="activeUser.id"></Profile>-->
      </template>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Util/Modal.vue';
import ModalSm from '@/components/Util/ModalSm.vue';
import InputCom from '@/components/Util/InputCom.vue';
import AnimatedButton from '@/components/Util/AnimatedButton.vue';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import ToggleSwitch from '@/components/Util/ToggleSwitch.vue';
import List from '@/components/admin/List.vue';

export default {
  name: 'Templates',
  components: {
    ToggleSwitch,
    AnimatedButton,
    Modal,
    ModalSm,
    InputCom,
    VSwatches,
    List,
  },
  data() {
    return {
      templates: [],
      activeTemplate: {},
      slotTemplate: {},
    };
  },
  computed: {

  },
  methods: {
    index() {
      this.$axios.get('templates',
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          this.templates = response.data.data;
        })
        .catch(() => {
          // console.log(error);
        });
    },
    templateUpdate(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.put(`templates/${this.slotTemplate.id}`, this.slotTemplate,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          const index = this.templates.findIndex((u) => u.id === response.data.data.id);
          this.templates.splice(index, 1, response.data.data);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((e) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: e,
            type: 'error',
          });
        });
    },
    templateDelete(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.delete(`templates/${this.activeTemplate.id}`,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then(() => {
          const index = this.templates.findIndex((u) => u.id === this.activeTemplate.id);
          this.templates.splice(index, 1);
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.$toast.open({
            message: 'Erfolgreich gelöscht!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data.error,
            type: 'error',
          });
        });
    },
    templateStore(button) {
      if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'loading';
      this.$axios.post('templates', this.slotTemplate,
        { headers: { Authorization: `Bearer ${this.$store.getters['auth/authToken']}` } })
        .then((response) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'success';
          this.templates.push(response.data.data);
          this.$toast.open({
            message: 'Erfolgreich gespeichert!',
            type: 'success',
          });
        })
        .catch((error) => {
          if (typeof this.$refs[button] !== 'undefined') this.$refs[button].loadState = 'error';
          this.$toast.open({
            message: error.response.data,
            type: 'error',
          });
        });
    },
  },
  mounted() {
    this.index();
  },
  watch: {
    activeTemplate(value) {
      this.slotTemplate = { ...value };
    },
  },
};
</script>

<style scoped>
</style>
